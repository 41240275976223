import React from 'react';
import '../assets/css/main.css';
import '../assets/css/nice-select2.css';
import '../assets/css/swiper-bundle.min.css';
import {Notice, initForms, initLayoutScripts, getDatesSwipers} from '../assets/js/notice.js'
import service_logo from '../assets/img/services.jpg'
import Icon from 'react-icons-kit';
import {x} from 'react-icons-kit/feather/x';

//Перечисление секций, между которыми переключается страница
const Sections = {
	PreServiceSelection: 1,
	ServiceSelection: 2,
	Confirmation: 3,
	Summary: 4
}

let was_inited = false;
let schedule = {};

const UNAUTHED_TOKEN = "100001100011000011111010001000010";

export default class Service extends React.Component {
    constructor(props) {
        super(props);

        this.state = { section: Sections.PreServiceSelection, loading: false, cabuserinfo: props.cabuserinfo, onec: props.onec, family_data: props.family_data, error_messages: props.error_messages,
                        update_error_messages_callback: props.update_error_messages_callback, business_units: [], selected_business_unit_id: 0, specializations: [], selected_specialization_id: 0,
                        services: [], selected_service_id: 0, selected_service_id_second: 0, doctors: [], selected_doctor_id: -1, united: {}, dates_selection: {}, confirmation_for_pd: false,
                        selected_fam_member_uid: 0, router: props.router, search: "", search_applied: "", need_to_init: false, bus_uids: {}, srvs_ids: {}, emp_uids: {}, prices: {}, events: [],
                        open_description_for_doctor: 0 };

        //Если пользователь не авторизован, перенаправляем его на страницу входа
        if(!localStorage.getItem('accessToken'))
            window.location = "/login";
    }

    componentDidUpdate(prevProps) {
        //Если есть ошибки, выводим всплывающее окно с ними
        if(this.state.error_messages.length > 0)
            Notice.show(this.state.error_messages.join('<br/>'));

        if(prevProps.cabuserinfo !== this.props.cabuserinfo)
            this.setState({cabuserinfo: this.props.cabuserinfo});
        if(prevProps.onec !== this.props.onec)
            this.setState({onec: this.props.onec});
        if(prevProps.family_data !== this.props.family_data)
            this.setState({family_data: this.props.family_data});
        if(prevProps.error_messages !== this.props.error_messages)
            this.setState({error_messages: this.props.error_messages});
        if(prevProps.update_error_messages_callback !== this.props.update_error_messages_callback)
            this.setState({update_error_messages_callback: this.props.update_error_messages_callback});
        if(prevProps.router !== this.props.router)
            this.setState({router: this.props.router});

        if(this.state.need_to_init)
        {
            //Инициализируем скрипты от верстальщиков
            if(was_inited)
                initLayoutScripts();
            was_inited = true;
            initLayoutScripts();
            Notice.setHideCallback(() => { this.setState({error_messages: []}); this.state.update_error_messages_callback([]); });
            this.setState({need_to_init: false});
        }

        //Вызываем обработчик скролла
        this.handleScroll();

        //При необходимости сбрасываем параметры модального окна описания врача
        const form = document.getElementById("doctor_description");
        if(form)
            if(this.state.open_description_for_doctor != 0)
                if(!form.classList.contains('open'))
                    this.setState({open_description_for_doctor: 0});
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);

        //Включаем анимацию загрузки
        this.setState({ loading: true });

        //Счетчик обработанных запросов
        const requests_to_fetch = 1;
        let requests_processed = 0;

        //Запрашиваем события для карусели
        let events = [];
        fetch('/api/get-slider?code=1000001', {method: 'GET', headers:{'ZR-Access-Token': localStorage.getItem('accessToken')}})
            .then(response => response.json())
            .then(data => {
                //Парсим данные
                events = data;
                this.setState({ events: data });

                //Переходим к запросу картинок, если всё остальное получено
                if(++requests_processed >= requests_to_fetch)
                    this.get_events_images(events);
            })
            .catch((error) => {
                //Переходим к запросу картинок, если всё остальное получено
                if(++requests_processed >= requests_to_fetch)
                    this.get_events_images(events);
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    get_events_images = (events_original) => {
        //Если event`ов нет, выходим
        if(events_original.length === 0)
        {
            this.setState({loading: false, need_to_init: true});
            this.setState({united: {}});
            this.get_bus();
            return;
        }

        let events = events_original;
        let events_quantity = events_original.length;
        let events_processed = 0;
        for(let even_t of events)
        {
            //Запрашиваем аватар
            fetch('/api/GetImageById', {method: 'POST', headers:{'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken')},
                body: JSON.stringify({ "imgId": even_t.file_id })})
                .then(response => response.json())
                .then(data => {
                    //Выключаем анимацию загрузки, если все запросы обработаны
                    if(++events_processed >= events_quantity)
                        this.setState({loading: false, need_to_init: true});

                    //Проверяем наличие обязательных полей в ответе
                    if(!('result' in data) || !('body' in data))
                        return;
                    else
                    {
                        //В случае ошибки добавляем ее в состояние
                        if(!data.result)
                        {
                            //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                            if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                            {
                                localStorage.removeItem('accessToken');
                                window.location = "/login";
                            }
                        }
                        else
                        {
                            if(!('base64data' in data.body))
                                return;

                            //Парсим данные
                            even_t.bnr_img = data.body.base64data;
                        }
                    }

                    //Сохраняем результат, если все запросы обработаны
                    if(events_processed >= events_quantity)
                    {
                        this.setState({events: events});
                        this.setState({united: {}});
                        this.get_bus();
                    }
                })
                .catch((error) => {
                    //Выключаем анимацию загрузки, если все запросы обработаны
                    if(++events_processed >= events_quantity)
                    {
                        this.setState({loading: false, need_to_init: true});
                        this.setState({united: {}});
                        this.get_bus();
                    }
                });
        }
    }

    uniqueBy = (a, cond) => {
      return a.filter((e, i) => a.findIndex(e2 => cond(e, e2)) === i);
    }

    get_bus = () => {
        //Включаем анимацию загрузки
        this.setState({ loading: true });

        //Сбрасываем ошибки
        this.state.update_error_messages_callback([]);

        //Запрашиваем подразделения
        fetch('/api/GetBusinessUnitsList', {method: 'GET', headers:{'ZR-Access-Token': localStorage.getItem('accessToken')}})
            .then(response => response.json())
            .then(data => {
                //Выключаем анимацию загрузки
                this.setState({loading: false});

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                //В случае ошибки добавляем ее в состояние
                if(!data.result)
                {
                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                    {
                        localStorage.removeItem('accessToken');
                        window.location = "/login";
                    }
                    else
                        if(data.body.errorAns && this.state.error_messages.indexOf(data.body.errorAns) === -1)
                            this.state.update_error_messages_callback([...this.state.error_messages, data.body.errorAns]);
                }
                else
                {
                    //Парсим данные
                    let business_units = {};
                    for(let bu of data.body)
                    {
                        business_units[bu.bu_id] = {};
                        business_units[bu.bu_id].bu_name = bu.bu_altname ? bu.bu_altname : bu.bu_name;
                        business_units[bu.bu_id].bu_addr = bu.bu_addr;
                    }
                    this.setState({ business_units: business_units });

                    if(Object.keys(business_units).length > 0)
                    {
                        //Включаем анимацию загрузки
                        this.setState({ loading: true });

                        //Сбрасываем ошибки
                        this.state.update_error_messages_callback([]);

                        //Запрашиваем данные по конкретным бизнес-подразделениям
                        //Временный контейнер для хранения специализаций
                        let sps_temp = [];
                        //Итоговый контейнер для хранения специализаций
                        let sps = {};
                        //Сколько специализаций обработано
                        let sps_counter = 0;
                        //Временный контейнер для хранения услуг
                        let srvs_temp = [];
                        //Итоговый контейнер для хранения услуг
                        let srvs = {};
                        //Сколько услуг обработано
                        let srvs_counter = 0;
                        //Временный контейнер для хранения докторов
                        let doctors_temp = [];
                        //Итоговый контейнер для хранения докторов
                        let doctors = {};
                        //Сколько докторов обработано
                        let doctors_counter = 0;
                        //Сколько профилей подразделений обработано
                        let bu_profiles_counter = 0;
                        for(let bu_id of Object.keys(business_units))
                        {
                            //Запрашиваем специализации
                            const request = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
                                body: JSON.stringify({ "buId": bu_id + "" })
                            };
                            fetch('/api/GetSpecializationsList', request)
                                .then(response => response.json())
                                .then(data => {
                                    //Выключаем анимацию загрузки, если все запросы обработаны
                                    if(++sps_counter >= Object.keys(business_units).length && srvs_counter >= Object.keys(business_units).length && doctors_counter >= Object.keys(business_units).length)
                                        this.setState({loading: false});

                                    //Проверяем наличие обязательных полей в ответе
                                    if(!('result' in data) || !('body' in data))
                                    {
                                        //Устанавливаем ошибку и прекращаем обработку
                                        if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                                            this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                                        return;
                                    }

                                    //В случае ошибки добавляем ее в состояние
                                    if(!data.result)
                                    {
                                        //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                                        if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                                        {
                                            localStorage.removeItem('accessToken');
                                            window.location = "/login";
                                        }
                                        else
                                            if(data.body.errorAns && this.state.error_messages.indexOf(data.body.errorAns) === -1)
                                                this.state.update_error_messages_callback([...this.state.error_messages, data.body.errorAns]);
                                    }
                                    else
                                    {
                                        //Парсим данные
                                        sps_temp = this.uniqueBy([...sps_temp, ...data.body], (o1, o2) => o1.sp_id === o2.sp_id)
                                    }

                                    //Сохраняем список, если все запросы обработаны
                                    if(sps_counter >= Object.keys(business_units).length)
                                    {
                                        //Парсим данные из временного контейнера
                                        for(let sp of sps_temp)
                                        {
                                            sps[sp.sp_id] = {};
                                            sps[sp.sp_id].sp_name = sp.sp_name;
                                        }
                                        this.setState({ specializations: sps });

                                        //Если вообще все данные получены, вызываем функцию формирования единого объекта
                                        if(sps_counter >= Object.keys(business_units).length && srvs_counter >= Object.keys(business_units).length && doctors_counter >= Object.keys(business_units).length && bu_profiles_counter >= Object.keys(business_units).length)
                                            this.form_united(business_units, sps, srvs, doctors);
                                    }
                                })
                                .catch((error) => {
                                    //Выключаем анимацию загрузки, если все запросы обработаны
                                    if(++sps_counter >= Object.keys(business_units).length && srvs_counter >= Object.keys(business_units).length && doctors_counter >= Object.keys(business_units).length)
                                        this.setState({loading: false});
                                    //Устанавливаем ошибку
                                    if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                                        this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
                                });

                            //Запрашиваем услуги
                            const request2 = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
                                body: JSON.stringify({ "buId": bu_id + "" })
                            };
                            fetch('/api/GetServicesList', request2)
                                .then(response => response.json())
                                .then(data => {
                                    //Выключаем анимацию загрузки, если все запросы обработаны
                                    if(++srvs_counter >= Object.keys(business_units).length && sps_counter >= Object.keys(business_units).length && doctors_counter >= Object.keys(business_units).length && bu_profiles_counter >= Object.keys(business_units).length)
                                        this.setState({loading: false});

                                    //Проверяем наличие обязательных полей в ответе
                                    if(!('result' in data) || !('body' in data))
                                    {
                                        //Устанавливаем ошибку и прекращаем обработку
                                        if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                                            this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                                        return;
                                    }

                                    //В случае ошибки добавляем ее в состояние
                                    if(!data.result)
                                    {
                                        //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                                        if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                                        {
                                            localStorage.removeItem('accessToken');
                                            window.location = "/login";
                                        }
                                        else
                                            if(data.body.errorAns && this.state.error_messages.indexOf(data.body.errorAns) === -1)
                                                this.state.update_error_messages_callback([...this.state.error_messages, data.body.errorAns]);
                                    }
                                    else
                                    {
                                        //Парсим данные
                                        srvs_temp = [...srvs_temp, ...data.body];
                                    }

                                    //Сохраняем список, если все запросы обработаны
                                    if(srvs_counter >= Object.keys(business_units).length)
                                    {
                                        //Соответствия uid`ов
                                        let bus_uids = {};
                                        let srvs_ids = {};
                                        let emp_uids = {};

                                        //Парсим данные из временного контейнера
                                        for(let srv of srvs_temp)
                                        {
                                            srvs[srv.srv_id] = srvs[srv.srv_id] ? srvs[srv.srv_id] : {};
                                            srvs[srv.srv_id].srv_name = srv.alt_name;
                                            srvs[srv.srv_id].srv_name2 = srv.srv_name;
                                            srvs[srv.srv_id].sps = srvs[srv.srv_id].sps ? srvs[srv.srv_id].sps : [];
                                            if(srvs[srv.srv_id].sps.indexOf(srv.sp_id) === -1)
                                                srvs[srv.srv_id].sps.push(srv.sp_id);
                                            srvs[srv.srv_id].emps = srvs[srv.srv_id].emps ? srvs[srv.srv_id].emps : [];
                                            if(srvs[srv.srv_id].emps.indexOf(srv.emp_id) === -1)
                                                srvs[srv.srv_id].emps.push(srv.emp_id);

                                            //Сохраняем соответствия uid`ов
                                            bus_uids[srv.bu_id] = srv.bu_uid;
                                            srvs_ids[srv.srv_uid] = srv.srv_id;
                                            emp_uids[srv.emp_id] = srv.emp_uid;
                                        }
                                        this.setState({ services: srvs, bus_uids: bus_uids, srvs_ids: srvs_ids, emp_uids: emp_uids });

                                        //Если вообще все данные получены, вызываем функцию формирования единого объекта
                                        if(sps_counter >= Object.keys(business_units).length && srvs_counter >= Object.keys(business_units).length && doctors_counter >= Object.keys(business_units).length && bu_profiles_counter >= Object.keys(business_units).length)
                                            this.form_united(business_units, sps, srvs, doctors);
                                    }
                                })
                                .catch((error) => {
                                    //Выключаем анимацию загрузки, если все запросы обработаны
                                    if(++srvs_counter >= Object.keys(business_units).length && sps_counter >= Object.keys(business_units).length && doctors_counter >= Object.keys(business_units).length && bu_profiles_counter >= Object.keys(business_units).length)
                                        this.setState({loading: false});
                                    //Устанавливаем ошибку
                                    if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                                        this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
                                });

                            //Запрашиваем врачей
                            const request3 = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
                                body: JSON.stringify({ "buId": bu_id + "" })
                            };
                            fetch('/api/GetDoctorsList', request3)
                                .then(response => response.json())
                                .then(data => {
                                    //Выключаем анимацию загрузки, если все запросы обработаны
                                    if(++doctors_counter >= Object.keys(business_units).length && srvs_counter >= Object.keys(business_units).length && sps_counter >= Object.keys(business_units).length && bu_profiles_counter >= Object.keys(business_units).length)
                                        this.setState({loading: false});

                                    //Проверяем наличие обязательных полей в ответе
                                    if(!('result' in data) || !('body' in data))
                                    {
                                        //Устанавливаем ошибку и прекращаем обработку
                                        if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                                            this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                                        return;
                                    }

                                    //В случае ошибки добавляем ее в состояние
                                    if(!data.result)
                                    {
                                        //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                                        if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                                        {
                                            localStorage.removeItem('accessToken');
                                            window.location = "/login";
                                        }
                                        else
                                            if(data.body.errorAns && this.state.error_messages.indexOf(data.body.errorAns) === -1)
                                                this.state.update_error_messages_callback([...this.state.error_messages, data.body.errorAns]);
                                    }
                                    else
                                    {
                                        //Парсим данные
                                        doctors_temp = [...doctors_temp, ...data.body];
                                    }

                                    //Сохраняем список, если все запросы обработаны
                                    if(doctors_counter >= Object.keys(business_units).length)
                                    {
                                        //Парсим данные из временного контейнера
                                        for(let doctor of doctors_temp)
                                        {
                                            doctors[doctor.emp_id] = doctors[doctor.emp_id] ? doctors[doctor.emp_id] : {};
                                            doctors[doctor.emp_id].fio = doctor.fio;
                                            doctors[doctor.emp_id].sps = doctors[doctor.emp_id].sps ? doctors[doctor.emp_id].sps : [];
                                            if(doctors[doctor.emp_id].sps.indexOf(doctor.sp_id) === -1)
                                                doctors[doctor.emp_id].sps.push(doctor.sp_id);
                                            doctors[doctor.emp_id].bus = doctors[doctor.emp_id].bus ? doctors[doctor.emp_id].bus : [];
                                            if(doctors[doctor.emp_id].bus.indexOf(doctor.bu_id) === -1)
                                                doctors[doctor.emp_id].bus.push(doctor.bu_id);
                                        }
                                        this.setState({ doctors: doctors });

                                        //Если вообще все данные получены, вызываем функцию формирования единого объекта
                                        if(sps_counter >= Object.keys(business_units).length && srvs_counter >= Object.keys(business_units).length && doctors_counter >= Object.keys(business_units).length && bu_profiles_counter >= Object.keys(business_units).length)
                                            this.form_united(business_units, sps, srvs, doctors);
                                    }
                                })
                                .catch((error) => {
                                    //Выключаем анимацию загрузки, если все запросы обработаны
                                    if(++doctors_counter >= Object.keys(business_units).length && srvs_counter >= Object.keys(business_units).length && sps_counter >= Object.keys(business_units).length && bu_profiles_counter >= Object.keys(business_units).length)
                                        this.setState({loading: false});
                                    //Устанавливаем ошибку
                                    if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                                        this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
                                });

                            //Запрашиваем профили подразделений
                            const request4 = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
                                body: JSON.stringify({ "buId": bu_id + "" })
                            };
                            fetch('/api/GetBuInfo', request4)
                                .then(response => response.json())
                                .then(data => {
                                    //Выключаем анимацию загрузки, если все запросы обработаны
                                    if(++bu_profiles_counter >= Object.keys(business_units).length && sps_counter >= Object.keys(business_units).length && srvs_counter >= Object.keys(business_units).length && doctors_counter >= Object.keys(business_units).length)
                                        this.setState({loading: false});

                                    //Проверяем наличие обязательных полей в ответе
                                    if(!('result' in data) || !('body' in data))
                                    {
                                        //Устанавливаем ошибку и прекращаем обработку
                                        if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                                            this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                                        return;
                                    }

                                    //В случае ошибки добавляем ее в состояние
                                    if(!data.result)
                                    {
                                        //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                                        if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                                        {
                                            localStorage.removeItem('accessToken');
                                            window.location = "/login";
                                        }
                                        else
                                            if(data.body.errorAns && this.state.error_messages.indexOf(data.body.errorAns) === -1)
                                                this.state.update_error_messages_callback([...this.state.error_messages, data.body.errorAns]);
                                    }
                                    else
                                    {
                                        //Парсим данные
                                        if(data.body.TEL)
                                            business_units[bu_id].tel = data.body.TEL;
                                    }

                                    //Сохраняем список, если все запросы обработаны
                                    if(bu_profiles_counter >= Object.keys(business_units).length)
                                    {
                                        this.setState({ business_units: business_units });

                                        //Если вообще все данные получены, вызываем функцию формирования единого объекта
                                        if(sps_counter >= Object.keys(business_units).length && srvs_counter >= Object.keys(business_units).length && doctors_counter >= Object.keys(business_units).length && bu_profiles_counter >= Object.keys(business_units).length)
                                            this.form_united(business_units, sps, srvs, doctors);
                                    }
                                })
                                .catch((error) => {
                                    //Выключаем анимацию загрузки, если все запросы обработаны
                                    if(++bu_profiles_counter >= Object.keys(business_units).length && sps_counter >= Object.keys(business_units).length && srvs_counter >= Object.keys(business_units).length && doctors_counter >= Object.keys(business_units).length)
                                        this.setState({loading: false});
                                    //Устанавливаем ошибку
                                    if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                                        this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
                                });
                        }
                    }
                }
            })
            .catch((error) => {
                //Выключаем анимацию загрузки
                this.setState({loading: false});
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
    }

    form_united = (bus, sps, srvs, doctors) => {
        //Формируем список united из имеющихся данных
        let united = this.state.united;
        for(let doctor of Object.entries(doctors))
        {
            if(doctor[1] === undefined)
                continue;

            //Если для этого врача уже загружено расписание, пропускаем
            if(united[doctor[0]] !== undefined && united[doctor[0]].loaded)
                continue;

            let item = {};
            item = {};
            item.fio = doctor[1].fio;

            item.sps = item.sps ? item.sps : {};
            for(let sp of doctor[1].sps)
            {
                if(sps[sp] === undefined)
                    continue;

                item.sps = item.sps ? item.sps : {};
                item.sps[sp] = item.sps[sp] ? item.sps[sp] : {};
                item.sps[sp].sp_name = sps[sp].sp_name;
            }

            item.bus = item.bus ? item.bus : {};
            for(let bu of doctor[1].bus)
            {
                if(bus[bu] === undefined)
                    continue;

                item.bus = item.bus ? item.bus : {};
                item.bus[bu] = item.bus[bu] ? item.bus[bu] : {};
                item.bus[bu].bu_addr = bus[bu].bu_addr;
                item.bus[bu].bu_name = bus[bu].bu_name;
            }

            item.srvs = item.srvs ? item.srvs : {};
            for(let srv of Object.keys(srvs))
            {
                if(srvs[srv].sps.length === 0)
                    continue;

                if(srvs[srv].emps.indexOf(doctor[0]) === -1)
                    continue;

                item.srvs = item.srvs ? item.srvs : {};
                item.srvs[srv] = item.srvs[srv] ? item.srvs[srv] : {};
                item.srvs[srv].srv_name = srvs[srv].srv_name;
                item.srvs[srv].srv_name2 = srvs[srv].srv_name2;
                item.srvs[srv].sp_id = srvs[srv].sps[0];
            }

            item.selected_bu = Object.keys(item.bus).length ? Object.keys(item.bus)[0] : 0;
            item.selected_date = 0;
            item.selected_time = -1;
            item.schedule = {};
            item.loaded = false;
            item.loading = false;
            item.profile = {};
            item.avatar = "";

            united[doctor[0]] = item;
        }

        this.setState({united: united, need_to_init: true});
    }

    handleScroll = (e) => {
        const elements = document.querySelectorAll('.appointment-list--item');

        for (const element of elements) {
            if(!this.state.united[element.dataset.key].loaded && !this.state.united[element.dataset.key].loading && this.isVisible(element))
            {
                //Помечаем факт начала загрузки данных элемента
                {
                    let united = this.state.united;
                    united[element.dataset.key].loading = true;
                    this.setState({united: united});
                }

                //Сбрасываем ошибки
                this.state.update_error_messages_callback([]);

                //Сколько записей обработано
                let bus_counter = 0;
                let sps_counter = {};
                for(let bu of Object.keys(this.state.united[element.dataset.key].bus))
                {
                    //Сколько специальностей обработано
                    sps_counter[bu] = 0;
                    for(let sp of Object.keys(this.state.united[element.dataset.key].sps))
                    {
                        /* eslint-disable */
                        fetch('/api/GetDoctorSchedule', {method: 'POST', headers:{'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken')},
                            body: JSON.stringify({ "buId": bu, "spId": sp, "empId": element.dataset.key })})
                            .then(response => response.json())
                            .then(data => {
                                ++sps_counter[bu];

                                //Проверяем наличие обязательных полей в ответе
                                if(!('result' in data) || !('body' in data))
                                {
                                    //Устанавливаем ошибку и прекращаем обработку
                                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                                }
                                else
                                {
                                    //В случае ошибки добавляем ее в состояние
                                    if(!data.result)
                                    {
                                        //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                                        if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                                        {
                                            localStorage.removeItem('accessToken');
                                            window.location = "/login";
                                        }
                                        else
                                            if(data.body.errorAns && this.state.error_messages.indexOf(data.body.errorAns) === -1)
                                                if(data.body.errorAns !== "Расписание врача не обнаружено.")
                                                    this.state.update_error_messages_callback([...this.state.error_messages, data.body.errorAns]);
                                    }
                                    else
                                    {
                                        //Парсим данные
                                        let united = this.state.united;
                                        united[element.dataset.key].schedule = united[element.dataset.key].schedule ? united[element.dataset.key].schedule : {};
                                        united[element.dataset.key].schedule[bu] = united[element.dataset.key].schedule[bu] ? united[element.dataset.key].schedule[bu] : {};
                                        united[element.dataset.key].schedule[bu][sp] = data.body;
                                        united[element.dataset.key].selected_bu = united[element.dataset.key].selected_bu ? united[element.dataset.key].selected_bu : Object.keys(united[element.dataset.key].schedule)[0];
                                    }
                                }

                                if(sps_counter[bu] >= Object.keys(this.state.united[element.dataset.key].sps).length)
                                    if(++bus_counter >= Object.keys(this.state.united[element.dataset.key].bus).length)
                                        {
                                        }

                                //Выключаем анимацию загрузки и сохраням результат, если все запросы обработаны
                                if(sps_counter[bu] >= Object.keys(this.state.united[element.dataset.key].sps).length && bus_counter >= Object.keys(this.state.united[element.dataset.key].bus).length)
                                {
                                    //Помечаем факт загрузки данных
                                    let united = this.state.united;
                                    united[element.dataset.key].loaded = true;
                                    united[element.dataset.key].loading = false;
                                    this.setState({united: united, need_to_init: true});
                                }
                            })
                            .catch((error) => {
                                if(sps_counter[bu] >= Object.keys(this.state.united[element.dataset.key].sps).length)
                                    if(++bus_counter >= Object.keys(this.state.united[element.dataset.key].bus).length)
                                    {
                                    }

                                //Выключаем анимацию загрузки и сохраням результат, если все запросы обработаны
                                if(sps_counter[bu] >= Object.keys(this.state.united[element.dataset.key].sps).length && bus_counter >= Object.keys(this.state.united[element.dataset.key].bus).length)
                                {
                                    //Помечаем факт загрузки данных
                                    let united = this.state.united;
                                    united[element.dataset.key].loaded = true;
                                    united[element.dataset.key].loading = false;
                                    this.setState({united: united, need_to_init: true});
                                }
                                //Устанавливаем ошибку
                                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
                            });
                        /* eslint-enable */
                    }
                }

                fetch('/api/GetEmpProfile', {method: 'POST', headers:{'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken')},
                    body: JSON.stringify({ "empId": element.dataset.key })})
                    .then(response => response.json())
                    .then(data => {
                        //Проверяем наличие обязательных полей в ответе
                        if(!('result' in data) || !('body' in data))
                        {
                            //Устанавливаем ошибку и прекращаем обработку
                            if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                                this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                        }
                        else
                        {
                            //В случае ошибки добавляем ее в состояние
                            if(!data.result)
                            {
                                //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                                if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                                {
                                    localStorage.removeItem('accessToken');
                                    window.location = "/login";
                                }
                                else
                                    if(data.body.errorAns && this.state.error_messages.indexOf(data.body.errorAns) === -1)
                                        this.state.update_error_messages_callback([...this.state.error_messages, data.body.errorAns]);
                            }
                            else
                            {
                                //Парсим данные
                                let united = this.state.united;
                                united[element.dataset.key].profile = data.body;
                                this.setState({united: united, need_to_init: true});

                                //Запрашиваем аватар
                                fetch('/api/GetImageById', {method: 'POST', headers:{'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken')},
                                    body: JSON.stringify({ "imgId": united[element.dataset.key].profile.PICTURE })})
                                    .then(response => response.json())
                                    .then(data => {
                                        //Проверяем наличие обязательных полей в ответе
                                        if(!('result' in data) || !('body' in data))
                                            return;
                                        else
                                        {
                                            //В случае ошибки добавляем ее в состояние
                                            if(!data.result)
                                            {
                                                //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                                                if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                                                {
                                                    localStorage.removeItem('accessToken');
                                                    window.location = "/login";
                                                }
                                            }
                                            else
                                            {
                                                if(!('base64data' in data.body))
                                                    return;

                                                //Парсим данные
                                                let united = this.state.united;
                                                united[element.dataset.key].avatar = data.body.base64data;
                                                this.setState({united: united, need_to_init: true});
                                            }
                                        }
                                    })
                                    .catch((error) => {

                                    });
                            }
                        }
                    })
                    .catch((error) => {
                        //Устанавливаем ошибку
                        if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                            this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
                    });
            }
        }
    }

    getDayName = (year, day, month) => {
        let date = new Date(year, month - 1, day);
        return date.toLocaleDateString('ru-RU', {weekday: 'short'});
    }

    isVisible = (el) => {
        const rect = el.getBoundingClientRect();
        const vWidth = window.innerWidth || document.documentElement.clientWidth;
        const vHeight = window.innerHeight || document.documentElement.clientHeight;
        const efp = function (x, y) { return document.elementFromPoint(x, y); };
        // Return false if it's not in the viewport
        if (rect.right < 0 || rect.bottom < 0
            || rect.left > vWidth || rect.top > vHeight) { return false; }
        // Return true if any of its four corners are visible
        return (
            el.contains(efp(rect.left, rect.top))
            || el.contains(efp(rect.right, rect.top))
            || el.contains(efp(rect.right, rect.bottom))
            || el.contains(efp(rect.left, rect.bottom))
        );
    };

    changeDateFormat = (date) => {
        if(date.length < 10)
            return date;
        let new_date = date[8] + date[9] + '.' + date[5] + date[6] + '.' + date[0] + date[1] + date[2] + date[3] + date.substring(10, date.length);
        return new_date;
    }

    render() {
        schedule = {};
        {
            for(let doctor of Object.entries(this.state.united).filter((entry) => this.state.selected_business_unit_id == 0 || entry[1].schedule[this.state.selected_business_unit_id] != undefined)
                            .filter((entry) => this.state.selected_specialization_id == 0 || Object.keys(entry[1].sps).includes(this.state.selected_specialization_id + ""))
                            .filter((entry) => this.state.selected_service_id == 0 || Object.keys(entry[1].srvs).includes(this.state.selected_service_id + "")))
            {
                if(this.state.united[doctor[0]].selected_bu == 0)
                    continue;

                if(this.state.united[doctor[0]].schedule[this.state.united[doctor[0]].selected_bu] != undefined)
                    for(let sp_dates of Object.entries(this.state.united[doctor[0]].schedule[this.state.united[doctor[0]].selected_bu]))
                    {
                        if(this.state.selected_specialization_id != 0 && sp_dates[0] != this.state.selected_specialization_id)
                            continue;

                        for(let date of Object.entries(sp_dates[1]))
                        {
                            for(let time of Object.entries(date[1]))
                            {
                                schedule[doctor[0]] = schedule[doctor[0]] ? schedule[doctor[0]] : {};
                                schedule[doctor[0]][date[0]] = schedule[doctor[0]][date[0]] ? schedule[doctor[0]][date[0]] : {};
                                schedule[doctor[0]][date[0]][time[0]] = time[1];
                            }
                        }
                    }
            }
        }

        let bus = [];
        let sps = [];
        let srvs = [];
        {
            for(let entry of Object.entries(this.state.united).filter((entry) => !this.state.search_applied.length || entry[1].fio.toLowerCase().includes(this.state.search_applied.toLowerCase())))
            {
                for(let bu of Object.entries(entry[1].bus))
                {
                    if(bu[1] == undefined || Object.entries(bu[1]).length == 0)
                        continue;

                    if(bus.indexOf(bu[0]) == -1)
                        bus.push(bu[0]);
                }
            }

            for(let entry of Object.entries(this.state.united).filter((entry) => this.state.selected_business_unit_id == 0 || entry[1].bus[this.state.selected_business_unit_id] != undefined)
                            .filter((entry) => !this.state.search_applied.length || entry[1].fio.toLowerCase().includes(this.state.search_applied.toLowerCase())))
            {
                for(let sp of Object.entries(entry[1].sps))
                {
                    if(sp[1] == undefined || Object.entries(sp[1]).length == 0)
                        continue;

                    if(sps.indexOf(sp[0]) == -1)
                        sps.push(sp[0]);
                }
            }

            for(let entry of Object.entries(this.state.united).filter((entry) => this.state.selected_business_unit_id == 0 || entry[1].bus[this.state.selected_business_unit_id] != undefined)
                            .filter((entry) => this.state.selected_specialization_id == 0 || Object.keys(entry[1].sps).includes(this.state.selected_specialization_id + ""))
                            .filter((entry) => !this.state.search_applied.length || entry[1].fio.toLowerCase().includes(this.state.search_applied.toLowerCase())))
            {
                for(let srv of Object.entries(entry[1].srvs))
                {
                    if(srvs.indexOf(srv[0]) == -1)
                        srvs.push(srv[0]);
                }
            }
        }

        if(this.state.section === Sections.PreServiceSelection)
        {
            return (
                <>
                    {this.state.loading &&
                    <div class="pre-loader">
                        <div class="loader-spinner">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    }

                    {this.state.events.length !== 0 &&
                    <div class="articles">
                        <p class="block-title">
                            ДЛЯ ВАС
                        </p>
                        <div class="swiper">
                            <div class="swiper-wrapper">
                                {this.state.events.map((data, index) =>
                                    <div class="swiper-slide" key={index}>
                                        <a href={data.bnr_href} class="articles--item" target="_blank">
                                            <img style={{backgroundImage: `url(data:image/jpeg;base64,${data.bnr_img})`}} class="articles--item__image"></img>
                                            <p class="articles--item__title">
                                                {data.bnr_name}
                                            </p>
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div class="navigation-item navigation-prev">
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11.5" cy="11.5" r="11.5" transform="rotate(-180 11.5 11.5)" fill="#7E7E7E"/>
                                <path d="M14.7158 5.63439C14.3416 5.26016 13.7348 5.26016 13.3606 5.63439L8.6768 10.3228C7.92892 11.0714 7.92921 12.2845 8.67738 13.0327L13.364 17.7193C13.7382 18.0936 14.3451 18.0936 14.7193 17.7193C15.0936 17.3451 15.0936 16.7383 14.7193 16.364L10.7081 12.3528C10.3338 11.9786 10.3338 11.3717 10.7081 10.9975L14.7158 6.98966C15.0901 6.61543 15.0901 6.00871 14.7158 5.63439Z" fill="white"/>
                            </svg>
                        </div>
                        <div class="navigation-item navigation-next">
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11.5" cy="11.5" r="11.5" fill="#7E7E7E"/>
                                <path d="M8.28415 17.3656C8.65839 17.7398 9.26521 17.7398 9.63944 17.3656L14.3232 12.6772C15.0711 11.9286 15.0708 10.7155 14.3226 9.96728L9.63599 5.28069C9.26176 4.90644 8.65494 4.90644 8.28069 5.28069C7.90644 5.65494 7.90644 6.26172 8.28069 6.63598L12.2919 10.6472C12.6662 11.0214 12.6662 11.6283 12.2919 12.0025L8.28415 16.0103C7.90989 16.3846 7.90989 16.9913 8.28415 17.3656Z" fill="white"/>
                            </svg>
                        </div>
                    </div>}
                    <div class="doctors--title">
                        <h1>
                            Услуги
                        </h1>
                        <div class="filter">
                            <div class="filter--inner">
                                <form action="">
                                    <span class="filter--close">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.16699 22.8327L22.6159 1.38377M1.16699 1.16602L22.6159 22.6149" stroke="#D30D15" stroke-width="2.16667" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                    <p class="filter--title">
                                        ФИЛЬТР
                                    </p>
                                    <div class={this.state.selected_business_unit_id !== 0 ? "filter--item with-dropdown filled" : "filter--item with-dropdown"}>
                                        <span class="icon">
                                            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.6667 6.7691V12.6559C15.6667 14.1959 15.6667 14.966 15.3669 15.5543C15.1033 16.0717 14.6826 16.4925 14.1652 16.7561C13.5769 17.0559 12.8068 17.0559 11.2667 17.0559H7.23333C5.69319 17.0559 4.92311 17.0559 4.33486 16.7561C3.81741 16.4925 3.39672 16.0717 3.13307 15.5543C2.83333 14.966 2.83333 14.1959 2.83333 12.6559V6.7685M17.5 8.80586L12.5196 3.27281C11.3868 2.01445 10.8205 1.38526 10.1535 1.15298C9.56772 0.948961 8.93008 0.949007 8.34424 1.15311C7.67727 1.3855 7.11106 2.01476 5.97855 3.2733L1 8.80586M9.25 8.80586V12.4725M11.0833 10.6392H7.41667" stroke="#D30D15" stroke-width="1.83333" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </span>
                                        Филиал
                                        <div class="with-dropdown--menu">
                                            <div class="with-dropdown--menu__inner">
                                                <div class="scroll">
                                                    <label class="with-dropdown--menu__item">
                                                        <input class="hidden-input" type="checkbox" value="0" checked={this.state.selected_business_unit_id == 0} onChange={(e) => { this.setState({ section: Sections.PreServiceSelection, selected_business_unit_id: Number("0"), selected_specialization_id: 0, selected_service_id: 0, need_to_init: true }); this.resetSelections();}}></input>
                                                        <span>Филиал</span>
                                                    </label>
                                                    {Object.entries(this.state.business_units).filter((unit) => bus.includes(unit[0])).sort((a, b) => a[1].bu_name.localeCompare(b[1].bu_name)).map((unit) =>
                                                    <label class="with-dropdown--menu__item" key={unit[0]}>
                                                        <input class="hidden-input" type="checkbox" value={unit[0]} checked={this.state.selected_business_unit_id == unit[0]} onChange={(e) => { this.setState({ section: Sections.PreServiceSelection, selected_business_unit_id: Number(e.target.value), selected_specialization_id: 0, selected_service_id: 0, need_to_init: true }); this.resetSelections(Number(e.target.value));}}></input>
                                                        <span>{unit[1].bu_name}</span>
                                                    </label>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="search-line">
                        <form action="" class="search-line--inner">
                            <label class="search-line--field">
                                <span class="search-line--field__title">Поиск</span>
                                <input class="search-line--field__input" type="text" name="search" placeholder="Введите название услуги" value={this.state.search} onChange={(e) => { this.setState({ search: e.target.value }); if(e.target.value === "") this.setState({ search_applied: "" }); }}></input>
                                {this.state.search.length > 0 && <div class="search-cancel"><Icon icon={x} onClick={(e) => {this.setState({section: Sections.PreServiceSelection, selected_business_unit_id: 0, selected_specialization_id: 0, selected_service_id: 0, search: "", search_applied: "", need_to_init: true}); this.resetSelections(Number(e.target.value));} }/></div>}
                            </label>
                            <button class="button-primary" onClick={(e) => { e.preventDefault(); this.setState({ section: Sections.PreServiceSelection, selected_business_unit_id: 0, selected_specialization_id: 0, selected_service_id: 0, search_applied: this.state.search, need_to_init: true }); this.resetSelections(Number(e.target.value));}}>Найти</button>
                        </form>
                    </div>
                    <div class="services-list">
                        {Object.entries(this.state.services).sort((a, b) => a[1].srv_name.localeCompare(b[1].srv_name))
                            .filter((srv) => !this.state.search_applied.length || srv[1].srv_name.toLowerCase().includes(this.state.search_applied.toLowerCase())).map((srv) =>
                        <a class="services-list--item" href="#" key={srv[0]} data-srv_id={srv[0]} onClick={this.toAppointmentSection}>
                            <span style={{backgroundImage: `url(${service_logo})`}} class="services-list--item__image"></span>
                            <p class="services-list--item__title">
                                {srv[1].srv_name}
                            </p>
                        </a>
                        )}
                    </div>
                </>
            );
        }
        else if(this.state.section === Sections.ServiceSelection)
        {
            return (
                <>
                    {this.state.loading &&
                    <div class="pre-loader">
                        <div class="loader-spinner">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    }

                    {this.state.events.length !== 0 &&
                    <div class="articles">
                        <p class="block-title">
                            ДЛЯ ВАС
                        </p>
                        <div class="swiper">
                            <div class="swiper-wrapper">
                                {this.state.events.map((data, index) =>
                                    <div class="swiper-slide" key={index}>
                                        <a href={data.bnr_href} class="articles--item" target="_blank">
                                            <img style={{backgroundImage: `url(data:image/jpeg;base64,${data.bnr_img})`}} class="articles--item__image"></img>
                                            <p class="articles--item__title">
                                                {data.bnr_name}
                                            </p>
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div class="navigation-item navigation-prev">
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11.5" cy="11.5" r="11.5" transform="rotate(-180 11.5 11.5)" fill="#7E7E7E"/>
                                <path d="M14.7158 5.63439C14.3416 5.26016 13.7348 5.26016 13.3606 5.63439L8.6768 10.3228C7.92892 11.0714 7.92921 12.2845 8.67738 13.0327L13.364 17.7193C13.7382 18.0936 14.3451 18.0936 14.7193 17.7193C15.0936 17.3451 15.0936 16.7383 14.7193 16.364L10.7081 12.3528C10.3338 11.9786 10.3338 11.3717 10.7081 10.9975L14.7158 6.98966C15.0901 6.61543 15.0901 6.00871 14.7158 5.63439Z" fill="white"/>
                            </svg>
                        </div>
                        <div class="navigation-item navigation-next">
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11.5" cy="11.5" r="11.5" fill="#7E7E7E"/>
                                <path d="M8.28415 17.3656C8.65839 17.7398 9.26521 17.7398 9.63944 17.3656L14.3232 12.6772C15.0711 11.9286 15.0708 10.7155 14.3226 9.96728L9.63599 5.28069C9.26176 4.90644 8.65494 4.90644 8.28069 5.28069C7.90644 5.65494 7.90644 6.26172 8.28069 6.63598L12.2919 10.6472C12.6662 11.0214 12.6662 11.6283 12.2919 12.0025L8.28415 16.0103C7.90989 16.3846 7.90989 16.9913 8.28415 17.3656Z" fill="white"/>
                            </svg>
                        </div>
                    </div>}
                    <h1>
                        {this.state.services[this.state.selected_service_id].srv_name}
                    </h1>
                    <div class="appointment-list">
                        {Object.entries(this.state.united).sort((a, b) => a[1].fio.localeCompare(b[1].fio))
                            .filter((entry) => this.state.selected_business_unit_id == 0 || entry[1].bus[this.state.selected_business_unit_id] != undefined)
                            .filter((entry) => this.state.selected_specialization_id == 0 || Object.keys(entry[1].sps).includes(this.state.selected_specialization_id + ""))
                            .filter((entry) => this.state.selected_service_id == 0 || Object.keys(entry[1].srvs).includes(this.state.selected_service_id + ""))
                            .map((entry) =>
                        <div class="appointment-list--item" key={entry[0]} data-key={entry[0]}>
                            <div class="appointment-list--item__doctor">
                                    {entry[1].avatar && entry[1].avatar.length > 0 && <div style={{backgroundImage: `url(data:image/jpeg;base64,${entry[1].avatar})`}} class="photo"></div>}
                                    {(entry[1].avatar === undefined || entry[1].avatar.length === 0) && <div class="photo-null">{((entry[1].fio && entry[1].fio.length > 0) ? entry[1].fio.charAt(0) : 'X') +
                                                                ((entry[1].fio && entry[1].fio.length > entry[1].fio.indexOf(" ")) ? entry[1].fio.charAt((entry[1].fio.indexOf(" ") + 1)) : 'Y')}</div>}
                                <div class="info">
                                    <p class="name">
                                        {entry[1].fio}
                                    </p>
                                    <p class="post">
                                        {entry[1].sp_name}
                                    </p>
                                    <a href="#" class={entry[1].profile.QINFO ? "" : "inactive-descr-link"} data-key={entry[0]} onClick={this.openDescription}>О специалисте</a>
                                    {this.state.selected_business_unit_id == 0 &&
                                    <div class="appointment-make--row" style={{"margin-top": "1rem", "margin-bottom": "0rem"}}>
                                        <div class={entry[1].selected_bu != 0 ? "value with-dropdown filled no-icon" : "value with-dropdown no-icon"} data-placeholder="Выбрать филиал">
                                            <span class={entry[1].selected_bu != 0 ? "with-dropdown--title filled" : "with-dropdown--title"}>{entry[1].selected_bu != 0 ? this.state.business_units[entry[1].selected_bu].bu_name : "Выбрать филиал"}</span>
                                            <div class="with-dropdown--menu">
                                                <div class="with-dropdown--menu__inner">
                                                    <div class="scroll">
                                                        {Object.entries(entry[1].bus).map((bu) =>
                                                        <label class="with-dropdown--menu__item" key={bu[0]}>
                                                            <input name="business-unit" class="hidden-input" type="radio" value={bu[0]} checked={entry[1].selected_bu == bu[0]} onChange={(e) => { let united = this.state.united; united[entry[0]].selected_bu = e.target.value; united[entry[0]].selected_date = 0; united[entry[0]].selected_time = -1; this.setState({united: united, need_to_init: true}); }}></input>
                                                            <span style={entry[1].selected_bu == bu[0] ? {"color": "#d30d15", "font-weight": "700"} : {}}>
                                                                {bu[1].bu_name}
                                                            </span>
                                                        </label>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {Object.entries(entry[1].bus).length > 1 &&
                                            <div class="another-unit">Выбрать другой филиал</div>}
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                            {!entry[1].loaded && <div class="no-schedule-container"><div class="no-schedule-inner loading-schedule">Расписание загружается</div></div>}
                            {entry[1].loaded && (schedule[entry[0]] == undefined || Object.entries(schedule[entry[0]]).length === 0) && <div class="no-schedule-container"><div class="no-schedule-inner">Нет доступного для записи времени в данном филиале. Попробуйте изменить филиал или получите дополнительную информацию по телефону <a href={entry[1].selected_bu && this.state.business_units[Number(entry[1].selected_bu)] && this.state.business_units[Number(entry[1].selected_bu)].tel ? ("tel:" + this.state.business_units[Number(entry[1].selected_bu)].tel) : "tel:2000-451"}>{entry[1].selected_bu && this.state.business_units[Number(entry[1].selected_bu)] && this.state.business_units[Number(entry[1].selected_bu)].tel ? this.state.business_units[Number(entry[1].selected_bu)].tel : "2000-451"}</a></div></div>}
                            {entry[1].loaded && schedule[entry[0]] != undefined && Object.entries(schedule[entry[0]]) &&
                                <div class="appointment-list--item__schedule">
                                    <div class="swiper-scrollbar"></div>
                                    <div class="dates swiper" doctorIndex={entry[0]}>
                                        <div class="swiper-wrapper">
                                            {schedule[entry[0]] != undefined && Object.entries(schedule[entry[0]]).length && Object.entries(schedule[entry[0]]).filter((data, index) => index == entry[1].selected_date).length &&
                                                    Object.entries(schedule[entry[0]]).map((date, index) =>
                                            <div class={entry[1].selected_date == index ? "swiper-slide swiper-slide-current" : "swiper-slide swiper-slide-not-current"} key={index} data-id={entry[0]} data-index={index} onClick={this.changeDate}>
                                                {this.getDayName(parseInt(date[0][0] + date[0][1] + date[0][2] + date[0][3]), parseInt(date[0][8] + date[0][9]), parseInt(date[0][5] + date[0][6])).toUpperCase()}, {date[0][8] + date[0][9] + "." + date[0][5] + date[0][6]}
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                    <div class="times swiper">
                                        <div class="swiper-wrapper">
                                            <div class="swiper-slide">
                                                {schedule[entry[0]] != undefined && Object.entries(schedule[entry[0]]).length && Object.entries(schedule[entry[0]]).filter((data, index) => index == entry[1].selected_date).length &&
                                                    Object.keys(Object.entries(schedule[entry[0]]).filter((data, index) => index == entry[1].selected_date)[0][1]).map((time, index) =>
                                                <div class={entry[1].selected_time == index ? "times--item active" : "times--item"} data-id={entry[0]} data-index={index} onClick={this.changeTime}>
                                                    <span>{time}</span>
                                                </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="navigation-item navigation-prev">
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="11.5" cy="11.5" r="11.5" transform="rotate(-180 11.5 11.5)" fill="#7E7E7E"/>
                                            <path d="M14.7158 5.63439C14.3416 5.26016 13.7348 5.26016 13.3606 5.63439L8.6768 10.3228C7.92892 11.0714 7.92921 12.2845 8.67738 13.0327L13.364 17.7193C13.7382 18.0936 14.3451 18.0936 14.7193 17.7193C15.0936 17.3451 15.0936 16.7383 14.7193 16.364L10.7081 12.3528C10.3338 11.9786 10.3338 11.3717 10.7081 10.9975L14.7158 6.98966C15.0901 6.61543 15.0901 6.00871 14.7158 5.63439Z" fill="white"/>
                                        </svg>
                                    </div>
                                    <div class="navigation-item navigation-next">
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="11.5" cy="11.5" r="11.5" fill="#7E7E7E"/>
                                            <path d="M8.28415 17.3656C8.65839 17.7398 9.26521 17.7398 9.63944 17.3656L14.3232 12.6772C15.0711 11.9286 15.0708 10.7155 14.3226 9.96728L9.63599 5.28069C9.26176 4.90644 8.65494 4.90644 8.28069 5.28069C7.90644 5.65494 7.90644 6.26172 8.28069 6.63598L12.2919 10.6472C12.6662 11.0214 12.6662 11.6283 12.2919 12.0025L8.28415 16.0103C7.90989 16.3846 7.90989 16.9913 8.28415 17.3656Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>}
                            <div class="appointment-list--item__action">
                                <p class="price">{entry[1].profile.AVGPRICE ? ("Стоимость от " + entry[1].profile.AVGPRICE + " руб") : ""}</p>
                                <button class="button-primary" data-index={entry[0]} onClick={this.toConfirmation}>
                                    Записаться
                                </button>
                            </div>
                        </div>
                        )}
                        {Object.entries(this.state.united).filter((entry) => this.state.selected_business_unit_id == 0 || entry[1].bus[this.state.selected_business_unit_id] != undefined)
                            .filter((entry) => this.state.selected_specialization_id == 0 || Object.keys(entry[1].sps).includes(this.state.selected_specialization_id + ""))
                            .filter((entry) => this.state.selected_service_id == 0 || Object.keys(entry[1].srvs).includes(this.state.selected_service_id + "")).length == 0 &&
                        <div>Нет свободного времени по указанным параметрам</div>}
                    </div>
                    <div class="col buttons">
                        <a href="#" class="button-lightgray back" onClick={this.fromAppointmentSection}>Назад</a>
                    </div>
                    <div id="doctor_description" class="modal">
                        <div class="modal-content doctor-description-modal">
                            <div class="modal-content--close">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.16699 22.8327L22.6159 1.38377M1.16699 1.16602L22.6159 22.6149" stroke="#D30D15" stroke-width="2.16667" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            {this.state.open_description_for_doctor != 0 &&
                            <div class="doctor-description-content">
                                <h1>{this.state.united[this.state.open_description_for_doctor].profile.LNAME} {this.state.united[this.state.open_description_for_doctor].profile.FNAME} {this.state.united[this.state.open_description_for_doctor].profile.SNAME}</h1>
                                <div class="doctor-description-container">
                                    <div>
                                        {this.state.united[this.state.open_description_for_doctor].avatar && this.state.united[this.state.open_description_for_doctor].avatar.length > 0 && <div style={{backgroundImage: `url(data:image/jpeg;base64,${this.state.united[this.state.open_description_for_doctor].avatar})`}} class="doctor-description-photo"></div>}
                                        {(this.state.united[this.state.open_description_for_doctor].avatar === undefined || this.state.united[this.state.open_description_for_doctor].avatar.length === 0) && <div class="doctor-description-photo-null">{((this.state.united[this.state.open_description_for_doctor].fio && this.state.united[this.state.open_description_for_doctor].fio.length > 0) ? this.state.united[this.state.open_description_for_doctor].fio.charAt(0) : 'X') +
                                                                    ((this.state.united[this.state.open_description_for_doctor].fio && this.state.united[this.state.open_description_for_doctor].fio.length > this.state.united[this.state.open_description_for_doctor].fio.indexOf(" ")) ? this.state.united[this.state.open_description_for_doctor].fio.charAt((this.state.united[this.state.open_description_for_doctor].fio.indexOf(" ") + 1)) : 'Y')}</div>}
                                    </div>
                                    <div>
                                        <h2>{this.state.united[this.state.open_description_for_doctor].profile.QINFO}</h2>
                                    </div>
                                </div>
                                <div>
                                    Образование:
                                    <ul>
                                        {this.state.united[this.state.open_description_for_doctor].profile.EDU.map((data, index) =>
                                            <li key={index}>{data.edu_date} - {data.edu_org} по специальности «{data.edu_spec}»</li>
                                        )}
                                        {this.state.united[this.state.open_description_for_doctor].profile.SERTS.map((data, index) =>
                                            <li key={index + this.state.united[this.state.open_description_for_doctor].profile.EDU.length}>
                                                {data.serts_date} - {data.serts_org} по программе «{data.serts_sert}»
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>}
                        </div>
                    </div>
                </>
            );
        }
        else if(this.state.section === Sections.Confirmation)
        {
            return (
                <>
                    {this.state.loading &&
                    <div class="pre-loader">
                        <div class="loader-spinner">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    }

                    {this.state.events.length !== 0 &&
                    <div class="articles">
                        <p class="block-title">
                            ДЛЯ ВАС
                        </p>
                        <div class="swiper">
                            <div class="swiper-wrapper">
                                {this.state.events.map((data, index) =>
                                    <div class="swiper-slide" key={index}>
                                        <a href={data.bnr_href} class="articles--item" target="_blank">
                                            <img style={{backgroundImage: `url(data:image/jpeg;base64,${data.bnr_img})`}} class="articles--item__image"></img>
                                            <p class="articles--item__title">
                                                {data.bnr_name}
                                            </p>
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div class="navigation-item navigation-prev">
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11.5" cy="11.5" r="11.5" transform="rotate(-180 11.5 11.5)" fill="#7E7E7E"/>
                                <path d="M14.7158 5.63439C14.3416 5.26016 13.7348 5.26016 13.3606 5.63439L8.6768 10.3228C7.92892 11.0714 7.92921 12.2845 8.67738 13.0327L13.364 17.7193C13.7382 18.0936 14.3451 18.0936 14.7193 17.7193C15.0936 17.3451 15.0936 16.7383 14.7193 16.364L10.7081 12.3528C10.3338 11.9786 10.3338 11.3717 10.7081 10.9975L14.7158 6.98966C15.0901 6.61543 15.0901 6.00871 14.7158 5.63439Z" fill="white"/>
                            </svg>
                        </div>
                        <div class="navigation-item navigation-next">
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11.5" cy="11.5" r="11.5" fill="#7E7E7E"/>
                                <path d="M8.28415 17.3656C8.65839 17.7398 9.26521 17.7398 9.63944 17.3656L14.3232 12.6772C15.0711 11.9286 15.0708 10.7155 14.3226 9.96728L9.63599 5.28069C9.26176 4.90644 8.65494 4.90644 8.28069 5.28069C7.90644 5.65494 7.90644 6.26172 8.28069 6.63598L12.2919 10.6472C12.6662 11.0214 12.6662 11.6283 12.2919 12.0025L8.28415 16.0103C7.90989 16.3846 7.90989 16.9913 8.28415 17.3656Z" fill="white"/>
                            </svg>
                        </div>
                    </div>}
                    <div class="appointment-make">
                        <h1>
                            Записаться на приём
                        </h1>
                        <form action="#" class="flex-row">
                            <div class="col">
                                <div class="doctor">
                                    {this.state.united[this.state.selected_doctor_id].avatar && this.state.united[this.state.selected_doctor_id].avatar.length > 0 && <div style={{backgroundImage: `url(data:image/jpeg;base64,${this.state.united[this.state.selected_doctor_id].avatar})`}} class="photo"></div>}
                                    {(this.state.united[this.state.selected_doctor_id].avatar === undefined || this.state.united[this.state.selected_doctor_id].avatar.length === 0) && <div class="photo-null">{((this.state.united[this.state.selected_doctor_id].fio && this.state.united[this.state.selected_doctor_id].fio.length > 0) ? this.state.united[this.state.selected_doctor_id].fio.charAt(0) : 'X') +
                                                                                   ((this.state.united[this.state.selected_doctor_id].fio && this.state.united[this.state.selected_doctor_id].fio.length > this.state.united[this.state.selected_doctor_id].fio.indexOf(" ")) ? this.state.united[this.state.selected_doctor_id].fio.charAt((this.state.united[this.state.selected_doctor_id].fio.indexOf(" ") + 1)) : 'Y')}</div>}
                                    <div class="info">
                                        <p class="name">
                                            {this.state.united[this.state.selected_doctor_id].fio}
                                        </p>
                                        <p class="post">
                                            {Object.entries(this.state.united[this.state.selected_doctor_id].sps).map((sp) =>
                                                <div key={sp[0]}>{sp[1].sp_name}</div>
                                            )}
                                        </p>
                                        <a href="#" class={this.state.united[this.state.selected_doctor_id].profile.QINFO ? "" : "inactive-descr-link"} data-key={this.state.selected_doctor_id} onClick={this.openDescription}>О специалисте</a>
                                    </div>
                                </div>
                                <p></p>
                                <p class="description">
                                    {this.state.business_units[this.state.united[this.state.selected_doctor_id].selected_bu].bu_name}<br/>
                                    {this.state.business_units[this.state.united[this.state.selected_doctor_id].selected_bu].bu_addr}<br/>
                                </p>
                            </div>
                            <div class="col">
                                <div class="appointment-make--row">
                                    <div class="title">
                                        ФИО специалиста
                                    </div>
                                    <div class="value">
                                        {this.state.united[this.state.selected_doctor_id].fio}
                                    </div>
                                </div>
                                <div class="appointment-make--row">
                                    <div class="title">
                                        Специальность
                                    </div>
                                    <div class="value">
                                        {Object.entries(this.state.united[this.state.selected_doctor_id].sps).map((sp) =>
                                            <div key={sp[0]}>{sp[1].sp_name}</div>
                                        )}
                                    </div>
                                </div>
                                <div class="appointment-make--row">
                                    <div class="title">
                                        Услуга
                                    </div>
                                    <div class="value">
                                        {this.state.services[this.state.selected_service_id].srv_name} - {this.state.prices[this.state.selected_service_id] ? this.state.prices[this.state.selected_service_id] : "X"} руб.
                                    </div>
                                </div>
                                <div class="appointment-make--row">
                                    <div class="title">
                                        Дата приёма
                                    </div>
                                    <div class="value">
                                        {this.changeDateFormat(Object.entries(schedule[this.state.selected_doctor_id]).filter((data, index) => index == this.state.united[this.state.selected_doctor_id].selected_date)[0][0].replaceAll('-', '.'))}
                                    </div>
                                </div>
                                <div class="appointment-make--row">
                                    <div class="title">
                                        Время приёма
                                    </div>
                                    <div class="value">
                                        {Object.entries(Object.entries(schedule[this.state.selected_doctor_id]).filter((data, index) => index == this.state.united[this.state.selected_doctor_id].selected_date)[0][1]).filter((data, index) => index == this.state.united[this.state.selected_doctor_id].selected_time)[0][0]}
                                    </div>
                                </div>
                                <div class="appointment-make--row">
                                    <div class="title">
                                        Филиал
                                    </div>
                                    <div class="value">
                                        {this.state.business_units[this.state.united[this.state.selected_doctor_id].selected_bu].bu_addr}
                                    </div>
                                </div>
                                <div class="appointment-make--row">
                                    <div class="title">
                                        ФИО пациента
                                    </div>
                                    <div class="value with-dropdown" data-placeholder="Выбрать пациента">
                                        <span class="with-dropdown--title">Выбрать пациента</span>
                                        <div class="with-dropdown--menu">
                                            <div class="with-dropdown--menu__inner">
                                                <div class="scroll">
                                                    {this.state.family_data.map((data, index) =>
                                                    <label class="with-dropdown--menu__item" key={index}>
                                                        <input name="pacient" class="hidden-input" type="radio" value={data.uid}  checked={this.state.selected_fam_member_uid == data.uid} onChange={(e) => this.setState({selected_fam_member_uid: e.target.value})}></input>
                                                        <span>
                                                            {data.name}
                                                        </span>
                                                    </label>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p style={{"display": "none"}}>
                                    Для записи онлайн требуется предоплата, после клика на кнопку “Записаться на приём” вы будете перенаправлены на онлайн кассу.
                                </p>
                                <div class="form-checkbox">
                                    <input id="agreement" type="checkbox" name="agreement" onChange={(e) => this.setState({ confirmation_for_pd: !this.state.confirmation_for_pd })} checked={this.state.confirmation_for_pd}></input>
                                    <label for="agreement" class="form-checkbox--label">
                                        Я даю согласие на обработку моих персональных данных и принимаю пользовательское соглашение
                                    </label>
                                </div>
                            </div>
                            <div class="col full">
                                <div class="buttons">
                                    <div class="col">
                                        <a href="#" class="adaptive-button-none button-lightgray back">Назад</a>
                                    </div>
                                    <div class="col">
                                        <a href="#" class="button-lightgray back adaptive-button-block" onClick={this.fromConfirmation}>Назад</a>
                                        <input type="submit" class="button-primary submit adaptive-button-size" value="Записаться на приём" onClick={this.toSummary}></input>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div id="doctor_description" class="modal">
                        <div class="modal-content doctor-description-modal">
                            <div class="modal-content--close">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.16699 22.8327L22.6159 1.38377M1.16699 1.16602L22.6159 22.6149" stroke="#D30D15" stroke-width="2.16667" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            {this.state.open_description_for_doctor != 0 &&
                            <div class="doctor-description-content">
                                <h1>{this.state.united[this.state.open_description_for_doctor].profile.LNAME} {this.state.united[this.state.open_description_for_doctor].profile.FNAME} {this.state.united[this.state.open_description_for_doctor].profile.SNAME}</h1>
                                <div class="doctor-description-container">
                                    <div>
                                        {this.state.united[this.state.open_description_for_doctor].avatar && this.state.united[this.state.open_description_for_doctor].avatar.length > 0 && <div style={{backgroundImage: `url(data:image/jpeg;base64,${this.state.united[this.state.open_description_for_doctor].avatar})`}} class="doctor-description-photo"></div>}
                                        {(this.state.united[this.state.open_description_for_doctor].avatar === undefined || this.state.united[this.state.open_description_for_doctor].avatar.length === 0) && <div class="doctor-description-photo-null">{((this.state.united[this.state.open_description_for_doctor].fio && this.state.united[this.state.open_description_for_doctor].fio.length > 0) ? this.state.united[this.state.open_description_for_doctor].fio.charAt(0) : 'X') +
                                                                    ((this.state.united[this.state.open_description_for_doctor].fio && this.state.united[this.state.open_description_for_doctor].fio.length > this.state.united[this.state.open_description_for_doctor].fio.indexOf(" ")) ? this.state.united[this.state.open_description_for_doctor].fio.charAt((this.state.united[this.state.open_description_for_doctor].fio.indexOf(" ") + 1)) : 'Y')}</div>}
                                    </div>
                                    <div>
                                        <h2>{this.state.united[this.state.open_description_for_doctor].profile.QINFO}</h2>
                                    </div>
                                </div>
                                <div>
                                    Образование:
                                    <ul>
                                        {this.state.united[this.state.open_description_for_doctor].profile.EDU.map((data, index) =>
                                            <li key={index}>{data.edu_date} - {data.edu_org} по специальности «{data.edu_spec}»</li>
                                        )}
                                        {this.state.united[this.state.open_description_for_doctor].profile.SERTS.map((data, index) =>
                                            <li key={index + this.state.united[this.state.open_description_for_doctor].profile.EDU.length}>
                                                {data.serts_date} - {data.serts_org} по программе «{data.serts_sert}»
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>}
                        </div>
                    </div>
                </>
            );
        }
        else
        {
            return (
                <>
                    {this.state.loading &&
                    <div class="pre-loader">
                        <div class="loader-spinner">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    }

                    {this.state.events.length !== 0 &&
                    <div class="articles">
                        <p class="block-title">
                            ДЛЯ ВАС
                        </p>
                        <div class="swiper">
                            <div class="swiper-wrapper">
                                {this.state.events.map((data, index) =>
                                    <div class="swiper-slide" key={index}>
                                        <a href={data.bnr_href} class="articles--item" target="_blank">
                                            <img style={{backgroundImage: `url(data:image/jpeg;base64,${data.bnr_img})`}} class="articles--item__image"></img>
                                            <p class="articles--item__title">
                                                {data.bnr_name}
                                            </p>
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div class="navigation-item navigation-prev">
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11.5" cy="11.5" r="11.5" transform="rotate(-180 11.5 11.5)" fill="#7E7E7E"/>
                                <path d="M14.7158 5.63439C14.3416 5.26016 13.7348 5.26016 13.3606 5.63439L8.6768 10.3228C7.92892 11.0714 7.92921 12.2845 8.67738 13.0327L13.364 17.7193C13.7382 18.0936 14.3451 18.0936 14.7193 17.7193C15.0936 17.3451 15.0936 16.7383 14.7193 16.364L10.7081 12.3528C10.3338 11.9786 10.3338 11.3717 10.7081 10.9975L14.7158 6.98966C15.0901 6.61543 15.0901 6.00871 14.7158 5.63439Z" fill="white"/>
                            </svg>
                        </div>
                        <div class="navigation-item navigation-next">
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="11.5" cy="11.5" r="11.5" fill="#7E7E7E"/>
                                <path d="M8.28415 17.3656C8.65839 17.7398 9.26521 17.7398 9.63944 17.3656L14.3232 12.6772C15.0711 11.9286 15.0708 10.7155 14.3226 9.96728L9.63599 5.28069C9.26176 4.90644 8.65494 4.90644 8.28069 5.28069C7.90644 5.65494 7.90644 6.26172 8.28069 6.63598L12.2919 10.6472C12.6662 11.0214 12.6662 11.6283 12.2919 12.0025L8.28415 16.0103C7.90989 16.3846 7.90989 16.9913 8.28415 17.3656Z" fill="white"/>
                            </svg>
                        </div>
                    </div>}
                    <h1>
                        Вы успешно записаны на приём
                    </h1>
                    <div class="appointment-make finish">
                        <div class="flex-row">
                            <div class="col">
                                <div class="appointment-make--row">
                                    <div class="title">
                                        ФИО специалиста
                                    </div>
                                    <div class="value">
                                        {this.state.united[this.state.selected_doctor_id].fio}
                                    </div>
                                </div>
                                <div class="appointment-make--row">
                                    <div class="title">
                                        Специальность
                                    </div>
                                    <div class="value">
                                        {this.state.united[this.state.selected_doctor_id].sps[this.state.services[this.state.selected_service_id_second].sps[0]].sp_name}
                                    </div>
                                </div>
                                <div class="appointment-make--row">
                                    <div class="title">
                                        Услуга
                                    </div>
                                    <div class="value">
                                        {this.state.united[this.state.selected_doctor_id].srvs[this.state.selected_service_id_second].srv_name}
                                    </div>
                                </div>
                                <div class="appointment-make--row">
                                    <div class="title">
                                        Дата приёма
                                    </div>
                                    <div class="value">
                                        {this.changeDateFormat(Object.entries(schedule[this.state.selected_doctor_id]).filter((data, index) => index == this.state.united[this.state.selected_doctor_id].selected_date)[0][0].replaceAll('-', '.'))}
                                    </div>
                                </div>
                                <div class="appointment-make--row">
                                    <div class="title">
                                        Время приёма
                                    </div>
                                    <div class="value">
                                        {Object.entries(Object.entries(schedule[this.state.selected_doctor_id]).filter((data, index) => index == this.state.united[this.state.selected_doctor_id].selected_date)[0][1]).filter((data, index) => index == this.state.united[this.state.selected_doctor_id].selected_time)[0][0]}
                                    </div>
                                </div>
                                <div class="appointment-make--row">
                                    <div class="title">
                                        Филиал
                                    </div>
                                    <div class="value">
                                        {this.state.business_units[this.state.united[this.state.selected_doctor_id].selected_bu].bu_name}<br/>
                                        {this.state.business_units[this.state.united[this.state.selected_doctor_id].selected_bu].bu_addr}<br/>
                                    </div>
                                </div>
                                <div class="appointment-make--row">
                                    <div class="title">
                                        ФИО пациента
                                    </div>
                                    <div class="value">
                                        {this.state.family_data.filter((data, index) => data.uid === this.state.selected_fam_member_uid)[0].name}
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="prices">
                                    <div class="prices--row">
                                        <span class="name">Полная стоимость</span>
                                        <div class="value">{this.state.prices[this.state.selected_service_id_second] ? this.state.prices[this.state.selected_service_id_second] : "X"} руб</div>
                                    </div>
                                    <div class="prices--row">
                                        <span class="name">Предоплата</span>
                                        <div class="value">0 руб</div>
                                    </div>
                                    <div class="prices--row primary">
                                        <span class="name">Оплатить в клинике</span>
                                        <div class="value">{this.state.prices[this.state.selected_service_id_second] ? this.state.prices[this.state.selected_service_id_second] : "X"} руб</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col buttons">
                                <a href="#" class="button-lightgray back" onClick={(e) => this.state.router.navigate("/")}>Вернуться на главную</a>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    }

    toAppointmentSection = (e) => {
        e.preventDefault();

        //Сбрасываем ошибки
        this.state.update_error_messages_callback([]);

        was_inited = false;
        this.setState({section: Sections.ServiceSelection, selected_service_id: e.currentTarget.dataset.srv_id, need_to_init: true})
    }

    fromAppointmentSection = (e) => {
        e.preventDefault();

        //Сбрасываем ошибки
        this.state.update_error_messages_callback([]);

        was_inited = false;
        this.setState({ section: Sections.PreServiceSelection, need_to_init: true });
    }

    resetSelections = (bu) => {
        let united = this.state.united;
        for(let entry of Object.entries(united))
        {
            united[entry[0]].selected_bu = bu ? bu : (Object.keys(united[entry[0]].bus).length > 0 ? Object.keys(united[entry[0]].bus)[0] : 0);
            united[entry[0]].selected_date = 0;
            united[entry[0]].selected_time = -1;
        }
        this.setState({united: united});
    }

    changeDate = (e) => {
        let united = this.state.united;
        united[e.currentTarget.dataset.id].selected_date = e.currentTarget.dataset.index;
        united[e.currentTarget.dataset.id].selected_time = -1;
        this.setState({united: united});

        //Получаем объекты swipers
        let date_swipers = getDatesSwipers();

        //Свайпаем слайдер, чтобы выбор оказался в поле зрения
        if(date_swipers[e.currentTarget.dataset.id] != undefined)
            while(Math.abs(united[e.currentTarget.dataset.id].selected_date - date_swipers[e.currentTarget.dataset.id].activeIndex > 4) || date_swipers[e.currentTarget.dataset.id].activeIndex > united[e.currentTarget.dataset.id].selected_date)
            {
                if(united[e.currentTarget.dataset.id].selected_date > date_swipers[e.currentTarget.dataset.id].activeIndex)
                    date_swipers[e.currentTarget.dataset.id].slideNext();
                else
                    date_swipers[e.currentTarget.dataset.id].slidePrev();
            }
    }

    changeAllDates = (date, e) => {
        //Получаем список врачей с информацией о выборе
        let united = this.state.united;
        //Получаем объекты swipers
        let date_swipers = getDatesSwipers();
        for(let entry of Object.entries(united))
        {
            united[entry[0]].selected_date = (schedule[entry[0]] == undefined || Object.keys(schedule[entry[0]]).indexOf(date) == -1) ? 0 : Object.keys(schedule[entry[0]]).indexOf(date);
            united[entry[0]].selected_time = -1;

            //Свайпаем слайдер, чтобы выбор оказался в поле зрения
            if(date_swipers[entry[0]] != undefined)
                while(Math.abs(united[entry[0]].selected_date - date_swipers[entry[0]].activeIndex > 4) || date_swipers[entry[0]].activeIndex > united[entry[0]].selected_date)
                {
                    if(united[entry[0]].selected_date > date_swipers[entry[0]].activeIndex)
                        date_swipers[entry[0]].slideNext();
                    else
                        date_swipers[entry[0]].slidePrev();
                }
        }
        this.setState({united: united});
    }

    changeTime = (e) => {
        let united = this.state.united;
        united[e.currentTarget.dataset.id].selected_time = e.currentTarget.dataset.index;
        this.setState({united: united});
    }

    openDescription = (e) => {
        e.preventDefault();

        const form = document.getElementById("doctor_description");

        if(!e.target.classList.contains('inactive-descr-link')) {
            this.setState({open_description_for_doctor: parseInt(e.target.dataset.key)});
            if(form && !form.classList.contains('open'))
                form.classList.add('open');
        }
    }

    toConfirmation = (e) => {
        e.preventDefault();

        //Сбрасываем ошибки
        this.state.update_error_messages_callback([]);

        //Если не выбрано время, устанавливаем ошибку и выходим
        if(this.state.united[e.currentTarget.dataset.index].selected_time == -1)
        {
            if(this.state.error_messages.indexOf("Время посещения не выбрано") === -1)
                this.state.update_error_messages_callback([...this.state.error_messages, "Время посещения не выбрано"]);
            return;
        }

        //Включаем анимацию загрузки
        this.setState({ loading: true });

        //Запрашиваем цены
        let doctor_index = e.currentTarget.dataset.index;
        const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
            body: JSON.stringify({ "buId": this.state.bus_uids[this.state.united[doctor_index].selected_bu] + "", "empId": this.state.emp_uids[doctor_index] + "" })
        };
        fetch('/api/GetEmpPriceList', request)
            .then(response => response.json())
            .then(data => {
                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    this.setState({loading: false});
                    return;
                }

                //В случае ошибки добавляем ее в состояние
                if(!data.result)
                {
                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                    {
                        localStorage.removeItem('accessToken');
                        window.location = "/login";
                    }
                    else
                        if(data.body.errorAns && this.state.error_messages.indexOf(data.body.errorAns) === -1)
                            this.state.update_error_messages_callback([...this.state.error_messages, data.body.errorAns]);
                    this.setState({loading: false});
                }
                else
                {
                    //Парсим данные
                    let prices = {};
                    for(let price of Object.entries(data.body))
                    {
                        if(this.state.srvs_ids[price[0]] === undefined)
                            continue;

                        prices[this.state.srvs_ids[price[0]]] = price[1].sum;
                    }

                    was_inited = false;
                    this.setState({ prices: prices, section: Sections.Confirmation, selected_doctor_id: doctor_index, selected_service_id_second: this.state.selected_service_id, need_to_init: true, loading: false });
                }
            })
            .catch((error) => {
                //Выключаем анимацию загрузки
                this.setState({loading: false});
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
    }

    fromConfirmation = (e) => {
        e.preventDefault();

        //Сбрасываем ошибки
        this.state.update_error_messages_callback([]);

        was_inited = false;
        this.setState({ section: Sections.ServiceSelection, need_to_init: true });
    }

    compareDateWithCurrent = (date1) => {
        var date_object1 = Date.parse(date1.split(' ')[0]);
        var date_object2 = new Date();

        return date_object1 >= date_object2;
    }

    toSummary = (e) => {
        e.preventDefault();

        //Сбрасываем ошибки
        this.state.update_error_messages_callback([]);

        //Флаг необходимости выхода из функции
        let exit = false;
        let error_messages = this.state.error_messages;

        //Если нет согласия на обработку ПД, устанавливаем ошибку и выходим
        if(!this.state.confirmation_for_pd)
        {
            if(error_messages.indexOf("Необходимо согласие на обработку персональных данных") === -1)
            {
                error_messages = [...error_messages, "Необходимо согласие на обработку персональных данных"];
                this.state.update_error_messages_callback(error_messages);
            }
            exit = true;
        }
        //Если не выбрана услуга, устанавливаем ошибку и выходим
        if(this.state.selected_service_id_second === 0)
        {
            if(error_messages.indexOf("Необходимо выбрать услугу") === -1)
            {
                error_messages = [...error_messages, "Необходимо выбрать услугу"];
                this.state.update_error_messages_callback(error_messages);
            }
            exit = true;
        }
        //Если не выбран член семьи, устанавливаем ошибку и выходим
        if(this.state.selected_fam_member_uid === 0)
        {
            if(error_messages.indexOf("Необходимо выбрать пациента") === -1)
            {
                error_messages = [...error_messages, "Необходимо выбрать пациента"];
                this.state.update_error_messages_callback(error_messages);
            }
            exit = true;
        }

        //Выходим из функции, если что-то пошло не так
        if(exit)
            return;

        //Включаем анимацию загрузки
        this.setState({ loading: true });

        //Запрашиваем будущие посещения
        const request = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
            body: JSON.stringify({})
        };
        fetch('/api/GetClSched', request)
            .then(response => response.json())
            .then(data => {
                //Выключаем анимацию загрузки
                this.setState({loading: false});

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                    return;
                }

                //Парсим данные
                if(data.result)
                {
                    //Перебираем поля объекта
                    let appointment_exists = false;
                    for(let property in data.body)
                    {
                        //Перебираем поля вложенного объекта
                        for(let row of data.body[property])
                        {
                            //Если нашли запись того же клиента к тому же врачу на ту же услугу, помечаем это
                            if(property == this.state.selected_fam_member_uid && this.compareDateWithCurrent(row.date) && row.empfio == this.state.united[this.state.selected_doctor_id].fio &&
                                this.state.united[this.state.selected_doctor_id].srvs[this.state.selected_service_id_second] &&
                                ((this.state.united[this.state.selected_doctor_id].srvs[this.state.selected_service_id_second].srv_name &&
                                    row.name.replace(/\s{2,}/g, ' ') == this.state.united[this.state.selected_doctor_id].srvs[this.state.selected_service_id_second].srv_name.replace(/\s{2,}/g, ' ')) ||
                                 (this.state.united[this.state.selected_doctor_id].srvs[this.state.selected_service_id_second].srv_name2 && row.name.replace(/\s{2,}/g, ' ') == this.state.united[this.state.selected_doctor_id].srvs[this.state.selected_service_id_second].srv_name2.replace(/\s{2,}/g, ' ')))
                              )
                            {
                                appointment_exists = true;
                                break;
                            }
                        }
                    }

                    //Если такая запись уже есть
                    if(appointment_exists)
                    {
                        //Устанавливаем ошибку и выходим
                        if(this.state.error_messages.indexOf("Запись к данному специалисту на данную услугу уже существует") === -1)
                            this.state.update_error_messages_callback([...this.state.error_messages, "Запись к данному специалисту на данную услугу уже существует"]);
                        return;
                    }
                    else
                    {
                        let day = Object.entries(schedule[this.state.selected_doctor_id]).filter((data, index) => index == this.state.united[this.state.selected_doctor_id].selected_date)[0][0];
                        let time = Object.entries(Object.entries(schedule[this.state.selected_doctor_id]).filter((data, index) => index == this.state.united[this.state.selected_doctor_id].selected_date)[0][1]).filter((data, index) => index == this.state.united[this.state.selected_doctor_id].selected_time)[0][0];

                        //Включаем анимацию загрузки
                        this.setState({ loading: true });

                        //Отправляем данные на сервер
                        const request2 = {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken')},
                            body: JSON.stringify({ "buId": this.state.united[this.state.selected_doctor_id].selected_bu + "",
                                "spId": this.state.services[this.state.selected_service_id_second].sps[0] + "",
                                "empId": this.state.selected_doctor_id, "srId": this.state.selected_service_id_second + "",
                                "apmtDate": day + " " + time, "code": "123", "cId": this.state.selected_fam_member_uid })
                        };
                        fetch('/api/AddEnroll', request2)
                            .then(response => response.json())
                            .then(data => {
                                //Выключаем анимацию загрузки
                                this.setState({loading: false});

                                //Проверяем наличие обязательных полей в ответе
                                if(!('result' in data) || !('body' in data))
                                {
                                    //Устанавливаем ошибку и прекращаем обработку
                                    if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                                        this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                                    return;
                                }

                                //В случае ошибки добавляем ее в состояние
                                if(!data.result)
                                {
                                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                                    {
                                        localStorage.removeItem('accessToken');
                                        window.location = "/login";
                                    }
                                    else
                                        if(data.body.errorAns && this.state.error_messages.indexOf(data.body.errorAns) === -1)
                                            this.state.update_error_messages_callback([...this.state.error_messages, data.body.errorAns]);
                                }
                                else
                                {
                                    //Извлекаем хэщ
                                    let hash = data.body;

                                    //Включаем анимацию загрузки
                                    this.setState({ loading: true });

                                    //Отправляем данные на сервер
                                    const request = {
                                        method: 'POST',
                                        headers: {'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken')},
                                        body: JSON.stringify({ "hash": hash, "code": "123" })
                                    };
                                    fetch('/api/AddAppointment', request)
                                        .then(response => response.json())
                                        .then(data => {
                                            //Выключаем анимацию загрузки
                                            this.setState({loading: false});

                                            //Проверяем наличие обязательных полей в ответе
                                            if(!('result' in data) || !('body' in data))
                                            {
                                                //Устанавливаем ошибку и прекращаем обработку
                                                if(this.state.error_messages.indexOf("Получен некорректный ответ от сервера") === -1)
                                                    this.state.update_error_messages_callback([...this.state.error_messages, "Получен некорректный ответ от сервера"]);
                                                return;
                                            }

                                            //В случае ошибки добавляем ее в состояние
                                            if(!data.result)
                                            {
                                                //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                                                if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                                                {
                                                    localStorage.removeItem('accessToken');
                                                    window.location = "/login";
                                                }
                                                else
                                                    if(data.body.errorAns && this.state.error_messages.indexOf(data.body.errorAns) === -1)
                                                        this.state.update_error_messages_callback([...this.state.error_messages, data.body.errorAns]);
                                            }
                                            else
                                            {
                                                //Включаем анимацию загрузки
                                                this.setState({ loading: true });

                                                //Отправляем данные на сервер
                                                const request3 = {
                                                    method: 'POST',
                                                    headers: {'Content-Type': 'application/json', 'ZR-Access-Token': UNAUTHED_TOKEN},
                                                    body: JSON.stringify({ "email": this.state.cabuserinfo && this.state.cabuserinfo.email ? this.state.cabuserinfo.email : "",
                                                                           "apdate": this.changeDateFormat(day), "aptime": time, "empfio": this.state.united[this.state.selected_doctor_id].fio,
                                                                           "butel": this.state.business_units[this.state.united[this.state.selected_doctor_id].selected_bu].tel,
                                                                           "buname": this.state.business_units[this.state.united[this.state.selected_doctor_id].selected_bu].bu_name + ", " +
                                                                                     this.state.business_units[this.state.united[this.state.selected_doctor_id].selected_bu].bu_addr })
                                                };
                                                fetch('/api/MailMe', request3)
                                                    .then(response => response.json())
                                                    .then(data => {
                                                        //Выключаем анимацию загрузки
                                                        this.setState({loading: false});

                                                        //Переходим на следующую секцию
                                                        this.setState({ section: Sections.Summary });
                                                    })
                                                    .catch((error) => {
                                                        //Выключаем анимацию загрузки
                                                        this.setState({loading: false});
                                                        //Устанавливаем ошибку
                                                        if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                                                            this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
                                                    });
                                            }
                                        })
                                        .catch((error) => {
                                            //Выключаем анимацию загрузки
                                            this.setState({loading: false});
                                            //Устанавливаем ошибку
                                            if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                                                this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
                                        });
                                }
                            })
                            .catch((error) => {
                                //Выключаем анимацию загрузки
                                this.setState({loading: false});
                                //Устанавливаем ошибку
                                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
                            });
                    }
                }
                else
                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                    {
                        localStorage.removeItem('accessToken');
                        window.location = "/login";
                    }
            })
            .catch((error) => {
                //Выключаем анимацию загрузки
                this.setState({loading: false});
                //Устанавливаем ошибку
                if(this.state.error_messages.indexOf("Ошибка получения ответа от сервера") === -1)
                    this.state.update_error_messages_callback([...this.state.error_messages, "Ошибка получения ответа от сервера"]);
            });
    }
}
